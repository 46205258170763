<template>
  <card-item legend="Cadastro de Arrematantes" ch-legend="ÚLTIMOS 12 MESES" icon="user-friends" icon-type="fa"
  :link="() => $router.push('/arrematantes')">
    <template v-slot:card-actions>
      <e-row class="show-on-hover" v-if="enableFilters" mr>
        <e-col style="max-width: 100px">
        <erp-s-field view="lr" label="">
        <erp-input v-model="filters.data1" class="sl-input input-xs" v-mask="'##/##/####'" />
        </erp-s-field>
        </e-col>
        <e-col style="max-width: 120px">
          <erp-s-field view="lr" label="até">
            <erp-input v-model="filters.data2" class="sl-input input-xs" v-mask="'##/##/####'" />
          </erp-s-field>
        </e-col>
      </e-row>
    </template>
    <div class="card-content min-pad">
      <div style="width: 100%" id="container-chart-cadastro-stats"></div>
    </div>
    <div class="diviser"></div>
    <div class="card-link-column">
      <card-item-link legend="Base total" :subtitle="total" icon-type="fa"/>
      <card-item-link legend="Aprovados" :subtitle="aprovados" icon-type="fa"/>
      <card-item-link legend="Análise" :subtitle="analise" icon-type="fa"/>
      <card-item-link legend="Sem Doc" :subtitle="semdoc" icon-type="fa"/>
    </div>
<!--    <div class="diviser"></div>
    <a @click="$router.push({name: 'arrematantes.lista'})" class="card-btn">
      <u-icon name="arrow_forward"/>
      Acessar arrematantes
    </a>-->
  </card-item>
</template>

<script>
import CardItem from '@/components/dashboard/includes/GridItem'
import Highcharts from 'highcharts'
// import {date} from 'uloc-vue'
import CardItemLink from '@/reuse/card/CardItemLink'
import Mixin from './mixin'
import * as Status from '@/domain/arrematante/helpers/Status'
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import {endOfMonth, format, subMonths, startOfMonth, startOfYear, endOfYear} from "date-fns"
import ERow from "components/layout/components/Row";
import ECol from "components/layout/components/Col";

let meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

export default {
  name: "CadastroStats",
  mixins: [Mixin],
  components: {ECol, ERow, ErpSField, ErpInput, CardItemLink, CardItem},
  props: {
    enableFilters: {
      type: Boolean,
      default: true
    }
  },
  data() {
    // const end = endOfMonth(new Date());
    const data2 = format(endOfMonth(new Date()), 'dd/MM/yyyy')
    const data1 = format(startOfMonth(subMonths(new Date(), 12)), 'dd/MM/yyyy')
    return {
      filters: {
        data1: data1,
        data2: data2,
      },
      categories: [],
      series: [],
      aprovados: 0,
      analise: 0,
      semdoc: 0
    }
  },
  computed: {
    Status () {
      return Status
    },
    cadastros() {
      if (!this.basicData || !this.basicData.cadastrosArrematante) {
        return []
      }
      return this.basicData.cadastrosArrematante
    },
    status() {
      if (!this.basicData || !this.basicData.cadastrosArrematanteStatus.status) {
        return []
      }
      return this.basicData.cadastrosArrematanteStatus.status
    },
    total () {
      if (!this.status) return 0
      let total = 0
      this.status.map(c => {
        total += Number(c.count)
      })
      return this.$options.filters.number(total)
    }
  },
  watch: {
    cadastros (v) {
      if (!this.cadastros) return
      let acessos = Object.values(this.cadastros.acessos)
      // Ajusta somente no máximo os 12 últimos meses
      let anos = {}
      acessos.map(a => {
        if (typeof anos[a.year] === "undefined") {
          anos[a.year] = []
        }
        anos[a.year].push(a)
      })
      acessos = []
      for (let ano in anos) {
        anos[ano].sort(function(a, b) {
          return (Number(a['year'] + String(a['month']).length === 1 ? '0' : '' + a['month'])) - (Number(b['year'] + String(b['month']).length === 1 ? '0' : '' + a['month']));
        })
        acessos = acessos.concat(anos[ano])
      }
      if (acessos.length > 12) {
        acessos = acessos.slice(acessos.length - 12)
      }
      this.categories = acessos.map(v => meses[Number(v['month'])-1])
      this.series = acessos.map(v => Number(v['count']))
      this.aprovados = this.subtotal(Status.STATUS_APROVADO)
      this.analise = this.subtotal(Status.STATUS_EM_ANALISE)
      this.semdoc = this.subtotal(Status.STATUS_PENDENTE)
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.series[0].setData(this.series)
          this.chart.xAxis[0].categories = this.categories;
          this.chart.xAxis[0].setCategories(this.categories)
          this.chart.redraw()
        }
      })
    }
  },
  mounted() {
    this.gerarGrafico()
  },
  methods: {
    gerarGrafico() {
      setTimeout(() => {
        this.$nextTick(() => {
          let title = 'Acessos ao site e app'

          Highcharts.setOptions({
            lang: {
              thousandsSep: '.'
            }
          })

          this.chart = Highcharts.chart('container-chart-cadastro-stats', {

            chart: {
              height: 300
            },
            title: {
              text: null
            },

            yAxis: {
              title: {
                text: null
              }
            },

            xAxis: {
              accessibility: {
                // rangeDescription: 'Range: 2010 to 2017'
              },
              categories: this.categories
            },


            legend: {
              layout: 'vertical',
              align: 'right',
              verticalAlign: 'middle'
            },

            plotOptions: {
              line: {
                dataLabels: {
                  enabled: true
                },
                enableMouseTracking: false
              }
            },

            series: [
              {
                name: 'Novos Cadastros',
                data: this.series
              }
            ],

            responsive: {
              rules: [{
                condition: {},
                chartOptions: {
                  legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                  }
                }
              }]
            }

          })
        })
      }, 300)
    },
    subtotal (s) {
      if (!this.status) return 0
      let total = 0
      this.status.filter(s1 => Number(s1.status) === Number(s)).map(c => {
        total += Number(c.count)
      })
      return this.$options.filters.number(total)
    }
  }
}
</script>
