<template>
  <a class="card-item-link" :class="css">
    <slot name="card-body">
      <div v-if="icon" class="card-h-icon">
        <slot name="card-icon">
          <u-icon :name="icon" :type="iconType" :icon-style="iconStyle" class="md"/>
        </slot>
      </div>
      <div class="card-h-content">
        <div class="content1">
          <span :class="legendClass">{{ legend }}</span>
          <div v-if="null !== subtitle" class="content2">{{ subtitle }}</div>
        </div>
        <div class="content-right" v-if="null !== right">{{ right }}</div>
      </div>
    </slot>
  </a>
</template>

<script>
export default {
  name: "CardItemLink",
  props: {
    'icon': String,
    'iconType': String,
    'iconStyle': String,
    'legend': [String, Number],
    'subtitle': {
      type: [String, Number],
      default: null
    },
    'right': [String, Number],
    'legendClass': String,
    'disabled': {
      type: Boolean,
      default: false
    },
    'noClick': {
      type: Boolean,
      default: false
    }
  },
  computed: {
    css () {
      let css = []
      if (this.disabled){
        css.push('disabled')
      }
      if (this.noClick){
        css.push('noClick')
      }
      return css
    }
  }
}
</script>
