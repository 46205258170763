<template>
  <div class="card-widget">
    <slot/>
  </div>
</template>

<script>
export default {
name: "CardWidget"
}
</script>
