<template>
  <div class="grid-item-box hoverable" :class="css">
    <div class="card-item">
      <slot name="card-header">
        <div class="card-header">
          <div class="card-h-icon">
            <slot name="card-icon">
              <u-icon :name="icon" :type="iconType" :icon-style="iconStyle || 'light'"/>
            </slot>
          </div>
          <div class="card-h-content">
            <a v-if="link" @click="() => link()">{{ legend }}</a>
            <span v-else>{{legend}}</span>
          </div>
          <div class="card-h-actions">
            <slot name="card-actions" />
            <u-icon name="expand-arrows-alt" type="fa" class="vue-draggable-handle show-on-hover" />
          </div>
        </div>
      </slot>
      <slot name="card-header-legend">
        <div class="card-header-legend">
          <slot name="card-header-legend-content">
            {{chLegend}}
          </slot>
        </div>
      </slot>
      <slot name="card-body">
        <div v-if="!loading" class="card-body no-drag">
          <slot/>
        </div>
        <div v-else class="card-body">
          <div class="wrapper">
            <u-fake-loader width="90%" />
            <u-fake-loader width="40%" />
            <u-fake-loader width="24%" />
          </div>
        </div>
      </slot>
      <slot name="card-footer-container">
        <div class="card-footer">
          <slot name="card-footer"></slot>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import {UFakeLoader} from 'uloc-vue'
export default {
  name: "Gridtem",
  components: {UFakeLoader},
  props: {
    'icon': {required: false},
    'iconType': {required: false},
    'iconStyle': {required: false},
    'legend': {required: false},
    'chLegend': {required: false},
    'link': {required: false},
    alertBorder: {
      required: false,
      type: String
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    css() {
      let css = []
      if (this.alertBorder) {
        css.push('border-' + this.alertBorder)
      }
      if (this.loading) {
        css.push('is-loading')
      }
      return css
    }
  }
}
</script>
