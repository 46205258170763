import http from '../../../utils/services/http'

export const getDashboardData = () => {
  let url = `/api/stats/dashboard`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const getEstatisticasCadastrosArrematantes = () => {
  let url = `/api/estatisticas-cache/cadastro-arrematantes`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export default {
  getEstatisticasCadastrosArrematantes
}
