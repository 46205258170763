<template>
  <card-item legend="Estatística de Acesso" ch-legend="ATUALIZAÇÃO DE USUÁRIOS ONLINE EM TEMPO REAL. ACESSOS AO SITE DE 15 EM 15 MINUTOS" icon="user-friends" icon-type="fa">
    <template v-slot:card-actions>
      <e-row class="show-on-hover" v-if="enableFilters" mr>
        <e-col style="max-width: 100px">
          <erp-s-field view="lr" label="">
            <erp-input v-model="filters.data1" class="sl-input input-xs" v-mask="'##/##/####'" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 120px">
          <erp-s-field view="lr" label="até">
            <erp-input v-model="filters.data2" class="sl-input input-xs" v-mask="'##/##/####'" />
          </erp-s-field>
        </e-col>
      </e-row>
    </template>
    <template v-slot:card-icon>
      <svg style="width: 24px; height: 24px" fill="currentColor" fill-rule="evenodd" height="100%" viewBox="0 0 24 24"
           width="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M12 14h10v-4H12zm0 8v-4h10v4z" opacity=".8"></path>
        <path d="M2 14h10v-4H2zm7 8h3v-4H9zM2 6h7V2H2z"></path>
      </svg>
    </template>
    <div v-if="showed" class="card-content min-pad">
      <div style="width: 100%" id="container-chart-acesso-stats"></div>
    </div>
    <div class="diviser"></div>
    <div class="col-grow-1">
      <div class="card-link-column">
        <card-item-link icon="user" legend="Logados online" :right="conectados.stats.logados"
                        icon-type="fa"/>
        <card-item-link icon="user-alt" icon-style="light" legend="Não logados" :right="conectados.stats.naoLogados" icon-type="fa"/>
      </div>
    </div>
  </card-item>
</template>

<script>
import CardItem from '@/components/dashboard/includes/GridItem'
import Highcharts from 'highcharts'
// import {date} from 'uloc-vue'
import Mixin from './mixin'
import CardItemLink from "@/reuse/card/CardItemLink"
import ERow from "components/layout/components/Row";
import ECol from "components/layout/components/Col";
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import {endOfMonth, format, subMonths, startOfMonth, startOfYear, endOfYear} from "date-fns"

const mock = {
  'conectados': {
    stats: {
      logados: 0,
      naoLogados: 0
    }
  },
  'identificados': [],
  'anonimos': []
}

export default {
  name: "AcessoStats",
  props: {
    enableFilters: {
      type: Boolean,
      default: true
    }
  },
  mixins: [Mixin],
  components: {CardItemLink, CardItem, ECol, ERow, ErpSField, ErpInput},
  data() {
    // const data2 = format(new Date(), 'dd/MM/yyyy HH:mm')
    // const data1 = format(startOfMonth(subMonths(new Date(), 12)), 'dd/MM/yyyy')
    return {
      filters: {
        data1: null,
        data2: null,
      },
      showed: false,
      conectados: {
        stats: {
          logados: 0,
          naoLogados: 0
        }
      },
      identificados: null,
      anonimos: null,
      intervalo: []
    }
  },
  mounted() {
    this.gerarGrafico()
  },
  computed: {
    acessos() {
      if (!this.basicData || !this.basicData.acessosSite) {
        return mock
      }
      return this.basicData.acessosSite
    }
  },
  watch: {
    acessos (v) {
      this.conectados = this.acessos.conectados
      this.identificados = this.acessos.identificados.map(v => v[1])
      this.anonimos = this.acessos.anonimos.map(v => v[1])
      this.intervalo = this.acessos.identificados.map(v => {
        return this.$options.filters.formatDate( v[0], 'HH:mm')
      })
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.series[0].setData(this.identificados)
          this.chart.series[1].setData(this.anonimos)
          this.chart.xAxis[0].categories = this.intervalo;
          this.chart.xAxis[0].setCategories(this.intervalo)
          this.chart.redraw()
        }
      })
    }
  },
  methods: {
    gerarGrafico() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.showed = true
          this.$nextTick(() => {
            this.identificados = this.acessos.identificados.map(v => v[1])
            this.anonimos = this.acessos.anonimos.map(v => v[1])

            Highcharts.setOptions({
              lang: {
                thousandsSep: '.'
              }
            })

            this.chart = new Highcharts.chart('container-chart-acesso-stats', {

              chart: {
                height: 260
              },
              title: {
                text: null
              },

              yAxis: {
                title: {
                  text: null
                },
                min: 0,
                minRange: 10
              },

              xAxis: {
                accessibility: {
                  // rangeDescription: 'Range: 2010 to 2017'
                },
                categories: this.intervalo
              },


              legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
              },

              plotOptions: {
                line: {
                  dataLabels: {
                    enabled: true
                  },
                  enableMouseTracking: false
                }
              },

              series: [
                {
                  name: 'Identificados',
                  data: this.identificados
                },
                {
                  name: 'Anônimos',
                  data: this.anonimos
                }
              ],

              responsive: {
                rules: [{
                  condition: {},
                  chartOptions: {
                    legend: {
                      layout: 'horizontal',
                      align: 'center',
                      verticalAlign: 'bottom'
                    }
                  }
                }]
              }

            })
          })
        }, 200)
      })
    }
  }
}
</script>
