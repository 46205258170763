<template>
  <div class="card-column">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "CardColumn"
}
</script>
