export default {
  inject: {
    dashboard: {
      default () {
        console.error('Component needs to be child of Dashboard with provide (dashboard)')
      }
    }
  },
  computed: {
    basicData () {
      if (!this.dashboard.basicData) {
        return null
      }
      return this.dashboard.basicData
    }
  }
}
